<template>
  <div></div>
</template>

<script>
import axios from 'axios';

export default {
    mounted() {
        this.$store.dispatch('logout');
        delete axios.defaults.headers.common.Authorization;
        delete localStorage.auth;
        this.$router.push('/');
    }
}
</script>
