<template>
    <b-container>
        <b-row class="text-center" style="margin-top: 100px; margin-bottom: 100px;">
            <b-col md="6" offset-md="3">
                <b-card title="Basar Login" tag="article" style="max-width: 30rem;" class="mb-2">
                    <b-alert variant="success" :show="success">
                        <b>Login Erfolgreich!</b>
                    </b-alert>

                    <b-alert variant="success" :show="resetSuccess">
                        <b>Wir haben Ihre Anfrage erhalten, bitte überprüfen Sie Ihr E-Mail Postfach.</b>
                    </b-alert>

                    <b-alert class="text-left" dismissible fade variant="danger" :show="error">
                        <b>Login Fehlgeschlagen!</b>
                        <p v-show="errorText">{{ errorText }}</p>
                    </b-alert>

                    <b-form @submit="onSubmit" v-show="!success && !resetSuccess" class="text-left">
                        <b-form-group horizontal :label-cols="3" label="E-Mail:" label-for="email">
                            <b-form-input name="email" id="email" type="email" ref="email" v-model="form.email" required placeholder="E-Mail Adresse"></b-form-input>
                        </b-form-group>
                        <b-form-group horizontal :label-cols="3" label="Passwort:" label-for="password" v-if="!forgot">
                            <b-form-input name="password" id="password" type="password" ref="password" v-model="form.password" required placeholder="Passwort"></b-form-input>
                        </b-form-group>

                        <b-form-group horizontal :label-cols="3" class="text-right" v-show="!forgot">
                            <a v-on:click="forgotPassword" href="#"><small>Passwort vergessen?</small></a>
                        </b-form-group>

                        <b-button type="submit" variant="primary" class="col-3 offset-9" v-show="!forgot">
                            <font-awesome-icon :icon="faSignInAlt" />
                            Login
                        </b-button>

                        <b-button type="submit" variant="primary" class="col-7 offset-5" v-show="forgot">
                            <font-awesome-icon :icon="faKey" />
                            neues Passwort anfordern
                        </b-button>
                    </b-form>
                </b-card>
           </b-col>
        </b-row>
    </b-container>
</template>

<script>
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { faKey } from '@fortawesome/free-solid-svg-icons/faKey';
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons/faSignInAlt';

export default {
    data: () => ({
        show: true,
        error: false,
        success: false,
        resetSuccess: false,
        forgot: false,
        errorText: false,

        form: { email: '', password: '' },

        faKey,
        faSignInAlt
    }),

    mounted() {
        this.$refs.email.focus();
    },

    methods: {
        async onSubmit(evt) {
            evt.preventDefault();
            this.error = false;

            try {
                if (this.forgot === false) {
                    const response = await axios.post('/login', this.form);

                    localStorage.auth = response.data.token;
                    axios.defaults.headers.common = { 'Authorization': `bearer ${response.data.token}` };
                    this.$store.dispatch('authenticate', {
                        token: response.data.token,
                        session: jwtDecode(response.data.token)
                    });

                    this.success = true;
                    setTimeout(() => this.$router.push('/'), 1500);
                } else {
                    await axios.post('/forgot', { email: this.form.email });
                    this.resetSuccess = true;
                }
            } catch(error) {
                this.error = true;
                if (error.response.data.error) {
                    this.errorText = error.response.data.error;
                }
            }
        },

        forgotPassword() {
            this.forgot = true;
        }
    }
}
</script>
