import Vue from 'vue';

// Vue.config.productionTip = false;

import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
Vue.component('font-awesome-icon', FontAwesomeIcon);

import App from './App.vue';
import store from './store';
import router from './router';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import * as Dxg from '@devexpress/dx-vue-grid';
import * as Dx from '@devexpress/dx-vue-grid-bootstrap4';
import '@devexpress/dx-vue-grid-bootstrap4/dist/dx-vue-grid-bootstrap4.css';
Object.keys(Dxg).forEach(k => Vue.component(k, Dxg[k]));
Object.keys(Dx).forEach(k => Vue.component(k, Dx[k]));

new Vue({
    store,
    router,
    render: h => h(App)
}).$mount('#app');
