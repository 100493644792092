<template>
    <b-container>
        <b-row class="text-center" style="margin-top: 100px; margin-bottom: 100px;">
            <b-col md="6" offset-md="3">
                <b-card title="Bitte warten..." tag="article" style="max-width: 30rem;" class="mb-2" />
           </b-col>
        </b-row>
    </b-container>
</template>

<script>
import axios from 'axios';
import jwtDecode from 'jwt-decode';

export default {
    mounted() {
        try {
            localStorage.auth = this.$route.params.token;
            this.$store.dispatch('authenticate', {
                token: this.$route.params.token,
                session: jwtDecode(this.$route.params.token)
            });
            axios.defaults.headers.common = { 'Authorization': `bearer ${this.$route.params.token}` };
        } catch (e) {
            // ignore exception
        }
        setTimeout(() => this.$router.push('/'), 150);
    }
}
</script>
