<template>
    <div v-if="!editing">
        <div class="text-right mb-2">
            <b-button @click="editing = {}">
                <font-awesome-icon :icon="faPlus" />
                Neue Kasse
            </b-button>
        </div>
        <div class="card">
            <b-table striped hover :fields="columns" :items="rows">
                <template #cell(actions)="data">
                    <b-button @click="editing = data.item" v-if="data.item._id">
                        <font-awesome-icon :icon="faPenToSquare" />
                    </b-button>
                </template>
            </b-table>
        </div>
    </div>
    <div v-else>
        <b v-if="editing._id">Kasse #{{editing._id}} bearbeiten</b>
        <b v-else>Neue Kasse anlegen</b>

        <b-alert variant="danger" :show="error">
            <p>
                <b>Es ist ein Fehler aufgetreten!</b>
            </p>

            {{ error }}
        </b-alert>

        <b-form @submit="onSubmit">
            <b-form-group label="Name:" label-for="name">
                <b-form-input id="name" type="text" v-model="editing.name" required placeholder="Name der Kasse..." />
            </b-form-group>

            <b-form-group label="Anfangsbestand:" label-for="initialInventory">
                <b-form-input id="initialInventory" type="text" v-model="editing.initialInventory" required placeholder="___,__ €" />
            </b-form-group>

            <b-button type="submit" variant="primary">
                <font-awesome-icon :icon="faFloppyDisk" />
                Speichern
            </b-button>
        </b-form>
    </div>
</template>

<script>
import axios from 'axios';
import { formatMoney, unformat } from 'accounting-js';
import { faPlus, faFloppyDisk, faPenToSquare } from '@fortawesome/free-solid-svg-icons';

export default {
    mounted() {
        this.reload();
    },

    data() {
        return {
            editing: false,
            error: null,

            columns: [
                { key: 'name', label: '#' },
                { key: 'initialInventory', label: 'Anfangsbestand' },
                { key: 'sales', label: 'Umsatz' },
                { key: 'commission', label: 'Provision' },
                { key: 'payout', label: 'Auszahlungsbetrag' },
                { key: 'balance', label: 'Kassenstand' },
                { key: 'inventory', label: 'Kassenbestand' },
                { key: 'difference', label: 'Differenz' },
                { key: 'actions', label: 'Aktionen' }
            ],

            rows: [],

            faPlus,
            faFloppyDisk,
            faPenToSquare
        };
    },

    methods: {
        async reload() {
            const res = await axios.get('/registers');

            this.rows = [{
                id: '-',
                name: '-',
                initialInventory: '',
                sales: '',
                commission: '',
                payout: '',
                balance: '',
                inventory: '',
                difference: ''
            }, ...res.data.map(row => {
                row.doc.initialInventory = formatMoney(row.doc.initialInventory, { symbol: '€', precision: 2, thousand: '.', decimal: ',' });
                row.doc.sales = formatMoney(row.doc.sales, { symbol: '€', precision: 2, thousand: '.', decimal: ',' });
                row.doc.commission = formatMoney(row.doc.commission, { symbol: '€', precision: 2, thousand: '.', decimal: ',' });
                row.doc.payout = formatMoney(row.doc.payout, { symbol: '€', precision: 2, thousand: '.', decimal: ',' });
                row.doc.balance = formatMoney(row.doc.balance, { symbol: '€', precision: 2, thousand: '.', decimal: ',' });
                row.doc.inventory = formatMoney(row.doc.inventory, { symbol: '€', precision: 2, thousand: '.', decimal: ',' });
                row.doc.difference = formatMoney(row.doc.difference, { symbol: '€', precision: 2, thousand: '.', decimal: ',' });
                return row.doc;
            })];

            this.updateSum();
        },

        updateSum() {
            this.rows[0].initialInventory = 0;
            this.rows[0].sales = 0;
            this.rows[0].commission = 0;
            this.rows[0].payout = 0;
            this.rows[0].balance = 0;
            this.rows[0].inventory = 0;
            this.rows[0].difference = 0;

            this.rows.forEach(row => {
                if (row.name !== '-') {
                    this.rows[0].initialInventory += unformat(row.initialInventory, ',');
                    this.rows[0].sales += unformat(row.sales, ',');
                    this.rows[0].commission += unformat(row.commission, ',');
                    this.rows[0].payout += unformat(row.payout, ',');
                    this.rows[0].balance += unformat(row.balance, ',');
                    this.rows[0].inventory += unformat(row.inventory, ',');
                    this.rows[0].difference += unformat(row.difference, ',');
                }
            });

            this.rows[0].initialInventory = formatMoney(this.rows[0].initialInventory, { symbol: '€', precision: 2, thousand: '.', decimal: ',' });
            this.rows[0].sales = formatMoney(this.rows[0].sales, { symbol: '€', precision: 2, thousand: '.', decimal: ',' });
            this.rows[0].commission = formatMoney(this.rows[0].commission, { symbol: '€', precision: 2, thousand: '.', decimal: ',' });
            this.rows[0].payout = formatMoney(this.rows[0].payout, { symbol: '€', precision: 2, thousand: '.', decimal: ',' });
            this.rows[0].balance = formatMoney(this.rows[0].balance, { symbol: '€', precision: 2, thousand: '.', decimal: ',' });
            this.rows[0].inventory = formatMoney(this.rows[0].inventory, { symbol: '€', precision: 2, thousand: '.', decimal: ',' });
            this.rows[0].difference = formatMoney(this.rows[0].difference, { symbol: '€', precision: 2, thousand: '.', decimal: ',' });
        },

        async onSubmit() {
            try {
                const update = {
                    name: this.editing.name,
                    initialInventory: unformat(this.editing.initialInventory, ',')
                };

                if (this.editing._id) {
                    const id = this.editing._id.split('/')[1];
                    await axios.put(`/registers/${id}`, update);
                } else {
                    await axios.post(`/registers/`, update);
                }
                this.editing = false;
                await this.reload();
            } catch (e) {
                this.error = e.message;
            }
        }
    }
}
</script>
