<template>
    <b-container>
        <b-row class="text-center" style="margin-top: 100px; margin-bottom: 100px;">
            <b-col md="6" offset-md="3">
                <b-card title="Passwort Zurücksetzen" tag="article" style="max-width: 30rem;" class="mb-2">
                    <b-alert variant="success" :show="success">
                        <p>
                            <b>Passwort wurde erfolgreich geändert!</b>
                        </p>

                        <p>
                            <b-button variant="success" class="col-4 offset-8" href="/">
                                <font-awesome-icon :icon="faArrowAltCircleRight" />
                                Fortfahren
                            </b-button>
                        </p>
                    </b-alert>

                    <b-alert dismissible fade variant="danger" :show="error">
                        Passwort konnte nicht zurückgesetzt werden!
                        Das neue Passwort muss aus mindestens 8 Zeichen bestehen und beide Passwörter müssen übereinstimmen.
                    </b-alert>

                    <b-alert variant="danger" :show="!token">
                        Passwort kann nicht zurückgesetzt werden!
                    </b-alert>

                    <b-form @submit="onSubmit" v-show="!success && token" class="text-left">
                        <b-form-group horizontal :label-cols="4" label="E-Mail:" label-for="email">
                            <b-form-input readonly id="email" type="email" ref="email" v-model="form.email" required placeholder="E-Mail Adresse"></b-form-input>
                        </b-form-group>
                        <b-form-group horizontal :label-cols="4" label="Passwort:" label-for="password">
                            <b-form-input id="password" type="password" ref="password" v-model="form.password" required placeholder="Passwort"></b-form-input>
                        </b-form-group>
                        <b-form-group horizontal :label-cols="4" label="Passwort wdh:" label-for="password2">
                            <b-form-input id="password2" type="password" ref="password2" v-model="form.password2" required placeholder="Passwort wiederholen"></b-form-input>
                        </b-form-group>

                        <b-button type="submit" variant="primary" class="col-6 offset-6">
                            <font-awesome-icon :icon="faKey" />
                            neues Passwort setzen
                        </b-button>
                    </b-form>
                </b-card>
           </b-col>
        </b-row>
    </b-container>
</template>

<script>
import axios from 'axios';
import jwtDecode from 'jwt-decode';

import { faKey } from '@fortawesome/free-solid-svg-icons/faKey';
import { faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons/faArrowAltCircleRight';

export default {
    data: () => ({
        show: true,
        error: false,
        success: false,
        token: false,
        form: {
            email: '',
            password: '',
            password2: ''
        },

        faKey,
        faArrowAltCircleRight
    }),
    mounted() {
        try {
            this.form.email = jwtDecode(this.$route.params.token).mail;
            this.form.token = this.$route.params.token;
            this.token = true;
            setImmediate(() => this.$refs.password.focus());
        } catch (e) {
            this.token = false;
        }
    },

    methods: {
        async onSubmit(evt) {
            evt.preventDefault();
            this.error = false;

            if (this.form.password.length < 8 || this.form.password !== this.form.password2) {
                this.error = true;
            } else {
                try {
                    localStorage.auth = this.form.token;
                    this.$store.dispatch('authenticate', {
                        token: this.form.token,
                        session: jwtDecode(this.form.token)
                    });
                    axios.defaults.headers.common = { 'Authorization': `bearer ${this.form.token}` };
                    await axios.post('/resetPassword', { password: this.form.password });
                    this.success = true
                } catch(error) {
                    console.log(error);
                    this.error = true;
                }
            }
        },
        forgotPassword() {
            this.forgot = true;
        }
    }
}
</script>
