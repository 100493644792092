import { render, staticRenderFns } from "./RegisterList.vue?vue&type=template&id=5f1f2214&"
import script from "./RegisterList.vue?vue&type=script&lang=js&"
export * from "./RegisterList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports