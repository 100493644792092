<template>
    <div class="row mobile-thingy">
        <pre>{{ $root.registers }}</pre>
        <div class="col-12 cake-buttons" style="text-align: center; margin-bottom: 20px;" v-if="cakes">
            <b-button class="mx-1 my-1 px-3 py-4" variant="outline-primary" size="lg" v-on:click="addCake(1, 'Muffin')">
                <b-button style="font-size: 22pt; padding-left: 18px; padding-right: 18px; margin-right: 20px;">a</b-button>
                <font-awesome-icon :icon="faPoop" />
                Muffin 1€
            </b-button>
            <!--
            <b-button class="mx-2 my-1 px-3 py-4" variant="outline-primary" size="lg" v-on:click="addCake(1.5, 'Kuchen/Obstkuchen')">
                <b-button style="font-size: 22pt; padding-left: 18px; padding-right: 18px; margin-right: 20px;">s</b-button>
                <font-awesome-icon :icon="faChartPie" />
                Kuchen/Obstkuchen 1,50€
            </b-button>
            -->
            <b-button class="mx-1 my-1 px-3 py-4" variant="outline-primary" size="lg" v-on:click="addCake(2, 'Torte')">
                <b-button style="font-size: 22pt; padding-left: 18px; padding-right: 18px; margin-right: 20px;">d</b-button>
                <font-awesome-icon :icon="faBirthdayCake" />
                Torte 2€
            </b-button>
            <b-button class="mx-1 my-1 px-3 py-4" variant="outline-primary" size="lg" v-on:click="addCake(1, 'Kaffee')">
                <b-button style="font-size: 22pt; padding-left: 18px; padding-right: 18px; margin-right: 20px;">y</b-button>
                <font-awesome-icon :icon="faCoffee" />
                Kaffee 1€
            </b-button>
            <br />
            <b-button class="mx-1 my-1 px-3 py-4" variant="outline-primary" size="lg" v-on:click="addCake(2, 'Getränk')">
                <b-button style="font-size: 22pt; padding-left: 18px; padding-right: 18px; margin-right: 20px;">x</b-button>
                <font-awesome-icon :icon="faCocktail" />
                Getränk 2€ inkl. Pfand
            </b-button>
            <b-button class="mx-1 my-1 px-3 py-4" variant="outline-primary" size="lg" v-on:click="addCake(-0.5, 'Pfandrückgabe')">
                <b-button style="font-size: 22pt; padding-left: 18px; padding-right: 18px; margin-right: 20px;">c</b-button>
                <font-awesome-icon :icon="faRecycle" />
                Pfandrückgabe -0,50€
            </b-button>
        </div>
        <div class="col-12 col-md-5">
            <b-form @submit="onSubmit" v-if="show && !cashing">
                <b-form-group label="Scannen:" label-for="scanInput">
                    <b-form-input ref="scan" id="scanInput" type="text" v-model="form.scan" placeholder="Barcode scannen" autocomplete="off" @keyup.native="filterKey" />
                </b-form-group>

                <b-form-group label="manuelle Eingabe:">
                    <b-form-input type="number" v-model="form.seller" placeholder="Verkaufsnummer" autocomplete="off" @focus.native="focus()" />
                    <b-form-input type="text" v-model="form.price" placeholder="Preis" autocomplete="off" @focus.native="focus()" />
                </b-form-group>

                <div style="text-align: right;">
                    <b-button type="submit" variant="primary">
                        <font-awesome-icon :icon="faPlusCircle" /> Eingabe
                    </b-button>
                    <b-button variant="secondary" @click="scanCamera">
                        <font-awesome-icon :icon="faBarcode" /> Scan
                    </b-button>
                </div>
            </b-form>

            <div class="mt-2" v-show="false">
                <b-button class="mx-1 my-1 px-5 px-md-4 py-4" variant="outline-primary" size="lg" v-on:click="keyboard('7')">7</b-button>
                <b-button class="mx-2 my-1 px-5 px-md-4 py-4" variant="outline-primary" size="lg" v-on:click="keyboard('8')">8</b-button>
                <b-button class="mx-1 my-1 px-5 px-md-4 py-4" variant="outline-primary" size="lg" v-on:click="keyboard('9')">9</b-button><br />
                <b-button class="mx-1 my-1 px-5 px-md-4 py-4" variant="outline-primary" size="lg" v-on:click="keyboard('4')">4</b-button>
                <b-button class="mx-2 my-1 px-5 px-md-4 py-4" variant="outline-primary" size="lg" v-on:click="keyboard('5')">5</b-button>
                <b-button class="mx-1 my-1 px-5 px-md-4 py-4" variant="outline-primary" size="lg" v-on:click="keyboard('6')">6</b-button><br />
                <b-button class="mx-1 my-1 px-5 px-md-4 py-4" variant="outline-primary" size="lg" v-on:click="keyboard('1')">1</b-button>
                <b-button class="mx-2 my-1 px-5 px-md-4 py-4" variant="outline-primary" size="lg" v-on:click="keyboard('2')">2</b-button>
                <b-button class="mx-1 my-1 px-5 px-md-4 py-4" variant="outline-primary" size="lg" v-on:click="keyboard('3')">3</b-button><br />
                <b-button class="mx-1 my-1 px-5 px-md-4 py-4" variant="outline-primary" size="lg" v-on:click="keyboard('<')">&lt;</b-button>
                <b-button class="mx-2 my-1 px-5 px-md-4 py-4" variant="outline-primary" size="lg" v-on:click="keyboard('0')">0</b-button>
                <b-button class="mx-1 my-1 px-5 px-md-4 py-4" variant="outline-primary" size="lg" v-on:click="keyboard(',')"><b>,</b></b-button>
            </div>

            <b-modal v-model="errorModal" @hidden="onHidden" title="Es ist ein Fehler aufgetreten!" :cancel-disabled="true">
                {{modalError}}
            </b-modal>
        </div>
        <div class="col-12 col-md-4 offset-md-3">
            <br />
            <b-card :title="`Summe: ${displaySum}`" tag="article" style="max-width: 20rem;" class="mb-2">
                <b-button v-on:click="cash" v-show="!cashing" class="float-right mt-3 mb-3" variant="primary"><font-awesome-icon :icon="faEuroSign" /> kassieren</b-button>

                <b-form @submit="onCash" v-if="cashing">
                    <b-form-group label="Summe:" label-for="sumInput">
                        <b-form-input id="sumInput" type="text" v-model="bill.sum" readonly placeholder="Summe" />
                    </b-form-group>

                    <b-form-group label="Geld gegeben:" label-for="cashInput">
                        <b-form-input ref="input" id="cashInput" type="text" v-model="bill.input" required placeholder="Geld gegeben" autocomplete="off" @keyup.native="calcReturn" @focus.native="focus()" />
                    </b-form-group>

                    <b-form-group label="Rückgeld:" label-for="returnInput" v-if="bill.return != ''" :description="bill.returnDescription">
                        <b-form-input id="returnInput" type="text" v-model="bill.return" readonly placeholder="Rückgeld" />
                    </b-form-group>

                    <b-button type="submit" variant="primary" class="float-right" v-if="bill.return != ''">
                        <font-awesome-icon :icon="faCheckCircle" /> Abschließen
                    </b-button>
                </b-form>

                <hr />

                <div v-for="(item, i) in items" :key="i" class="row col-12">
                    <div class="col-4">
                        <b-badge>{{ item.seller }}</b-badge>
                    </div>
                    <div class="col-8 text-right">
                        {{ item.price }}
                        <b-button v-on:click="deleteRow(i)" variant="link" size="sm" v-show="!cashing"><font-awesome-icon :icon="faTrash" /></b-button>
                    </div>
                </div>
            </b-card>
        </div>

        <div class="col-12 cake-buttons" style="text-align: center; margin-top: 20px;">
            <b-button class="mx-1 my-1 px-3 py-3" variant="outline-secondary" size="md" @click="hotkey('l')">
                <b-button style="font-size: 14pt; padding-left: 12px; padding-right: 12px; margin-right: 14px;" disabled>L</b-button>
                <font-awesome-icon :icon="faTrash" />
                Löschen
            </b-button>
            <b-button class="mx-2 my-1 px-3 py-3" variant="outline-secondary" size="md" @click="hotkey('k')">
                <b-button style="font-size: 14pt; padding-left: 12px; padding-right: 12px; margin-right: 14px;" disabled>K</b-button>
                <font-awesome-icon :icon="faEuroSign" />
                Kassieren
            </b-button>
            <b-button class="mx-1 my-1 px-3 py-3" variant="outline-secondary" size="md" @click="hotkey('b')">
                <b-button style="font-size: 14pt; padding-left: 12px; padding-right: 12px; margin-right: 14px;" disabled>B</b-button>
                <font-awesome-icon :icon="faBarcode" />
                Barcode
            </b-button>
        </div>

    </div>
</template>

<script>
import axios from 'axios';
import { Html5QrcodeScanner, Html5QrcodeSupportedFormats } from "html5-qrcode";
import { formatMoney, unformat } from 'accounting-js';
import { faBarcode, faTrash, faEuroSign, faPlusCircle, faTimesCircle, faCheckCircle, faCoffee, faBirthdayCake, faRecycle, faCocktail, faPoop, faChartPie } from '@fortawesome/free-solid-svg-icons';

export default {
    async mounted() {
        if (!this.$store.getters.hasCapability('cashier') && !this.$store.getters.hasCapability('admin')) {
            this.$router.push('/login');
        }

        if (!this.cakes) {
            this.$refs.scan.$el.focus();
        }

        try {
            this.cakes = (await axios.get('/registers')).data
                .filter(e => e.doc.id === this.$route.params.id && String(e.doc.name).toLowerCase().includes('kuchen'))
                .length > 0;
        } catch(e) {
            console.log(e);
            this.cakes = false;
        }

        window.addEventListener('keyup', (event) => {
            if (this.cakes) {
                switch (event.key) {
                    case 'a':
                        this.addCake.apply(this, [1, 'Muffin']);
                        event.preventDefault();
                        break;
                    /*
                    case 's':
                        event.preventDefault();
                        this.addCake.apply(this, [1.5, 'Kuchen/Obstkuchen']);
                        break;
                    */
                    case 'd':
                        event.preventDefault();
                        this.addCake.apply(this, [2, 'Torte']);
                        break;
                    case 'y':
                        event.preventDefault();
                        this.addCake.apply(this, [1, 'Kaffee']);
                        break;
                    case 'x':
                        event.preventDefault();
                        this.addCake.apply(this, [2, 'Getränk']);
                        break;
                    case 'c':
                        event.preventDefault();
                        this.addCake.apply(this, [-0.5, 'Pfandrückgabe']);
                        break;
                }
            }

            switch (event.key) {
                case 'n':
                case 'b':
                    event.preventDefault();
                    this.$refs.scan?.$el?.focus?.();
                    break;

                case 'k':
                    event.preventDefault();
                    this.cash.apply(this);
                    break;

                case 'l':
                    event.preventDefault();
                    if (this.items.length > 0) {
                        this.deleteRow.apply(this, [0]);
                    }
                    break;
            }
        });
    },
    data() {
        return {
            cakes: false,
            show: true,
            cashing: false,
            errorModal: false,
            modalError: '',

            sum: 0,
            displaySum: '',
            items: [],

            form: {
                scan: '',
                seller: '',
                price: ''
            },

            bill: {
                sum: '',
                input: '',
                return: '',
                returnDescription: ''
            },

            focusedElement: null,

            faBarcode,
            faTrash,
            faEuroSign,
            faPlusCircle,
            faTimesCircle,
            faCheckCircle,

            faCocktail,
            faCoffee,
            faBirthdayCake,
            faRecycle,
            faPoop,
            faChartPie
        };
    },

    methods: {
        async onCash(evt) {
            evt.preventDefault();

            try {
                await axios.post(`/registers/${this.$route.params.id}`, {
                    sum: this.sum,
                    displaySum: this.displaySum,
                    items: this.items,
                    bill: this.bill,
                    ts: new Date()
                });

                // reset
                this.show = true;
                this.cashing = false;

                this.sum = 0;
                this.displaySum = '';
                this.items = [];

                this.form.scan = '';
                this.form.seller = '';
                this.form.price = '';

                this.bill.sum = '';
                this.bill.input = '';
                this.bill.return = '';
                this.bill.returnDescription = '';

                if (!this.cakes) {
                    this.$nextTick(() => {
                        this.$refs.scan?.$el?.focus?.();
                    });
                }
            } catch (error) {
                this.showDialog('Es ist ein Fehler aufgetreten!');
            }
        },
        calcReturn() {
            let val = unformat(this.bill.input, ',') - this.sum;

            this.bill.return = formatMoney(val, { symbol: '€', precision: 2, thousand: '.', decimal: ',' });
            this.bill.returnDescription = [100, 50, 20, 10, 5, 2, 1, .5, .2, .1, .05, .02, .01].reduce((share, d) => {
                const res = Math.floor(val / d);
                if (res > 0) {
                    share.push(`${res} x ${formatMoney(d, { symbol: '€', thousand: '.', decimal: ',' })}`);
                }

                val = val % d;
                return share;
            }, []).join(' + ');
        },
        onSubmit(evt) {
            evt?.preventDefault?.();
            const item = {
                seller: parseInt(this.form.seller, 10),
                value: unformat(this.form.price, ','),
            };

            if (this.form.scan !== '') {
                // use scanned code instead
                item.code = this.form.scan;
                /*
                if (item.code.includes('UNDEFINED')) {
                    item.code = item.code.replace('NAN', '0').replace('UNDEFINED', '047');
                }
                */
                if (item.code.length === 9) {
                    // pfuhl codes
                    item.seller = parseInt(item.code.substr(0, 3), 10);
                    item.value = parseInt(item.code.substr(4), 10) / 100;
                } else {
                    if (item.code.length == 12) {
                        item.code = '0' + item.code;
                    }
                    if (item.code.length == 11 || item.code.length == 12) {
                        let c = item.code.substr(0, 4);
                        c += '000'.substr(0, 13 - item.code.length);
                        item.code = c + item.code.substr(4);
                    }
                    if (item.code.length != 13) {
                        this.showDialog('Barcode ungültig!');
                        return this.onReset(evt);
                    }
                    item.seller = parseInt(item.code.substr(4, 3), 10);
                    item.value = parseInt(item.code.substr(10, 3), 10) / 10;
                }

                if ((item.value * 100) % 50 !== 0) {
                    this.showDialog('Preis ungültig');
                    return this.onReset(evt);
                }
            }

            if (item.value > 0 || (item.value < 0 && this.$store.getters.hasCapability('admin'))) {
                axios.get(`/check/${item.seller}`).then((res) => {
                    if (res.data === true) {
                        item.price = formatMoney(item.value, {symbol: '€', precision: 2, thousand: '.', decimal: ','});
                        this.sum += item.value;
                        this.displaySum = formatMoney(this.sum, {symbol: '€', precision: 2, thousand: '.', decimal: ','});
                        this.items.unshift(item);
                        this.onReset(evt);
                    } else {
                        this.showDialog('Verkaufsnummer existiert nicht');
                    }
                });
            } else {
                this.onReset(evt);
            }
        },
        showDialog(str) {
            this.modalError = str;
            this.errorModal = true;

            new Audio('/alert.wav').play();
        },
        onReset(evt) {
            evt?.preventDefault?.();

            this.form.scan = '';
            this.form.seller = '';
            this.form.price = null;

            this.show = false;
            this.$nextTick(() => {
                this.show = true;
                this.$nextTick(() => {
                    this.$refs.scan?.$el?.focus?.();
                });
            });
        },
        cash() {
            this.cashing = true;
            this.bill.sum = this.displaySum;

            this.$nextTick(() => {
                this.$refs.input?.$el?.focus?.();
            });
        },
        deleteRow(i) {
            this.items.splice(i, 1);
            this.sum = this.items.reduce((sum, item) => sum + item.value, 0);
            this.displaySum = formatMoney(this.sum, {symbol: '€', precision: 2, thousand: '.', decimal: ','});
            if (this.$refs.scan?.$el) {
                this.$refs.scan.$el.focus();
            }
        },
        onHidden() {
            if (this.$refs.scan?.$el) {
                this.$refs.scan.$el.focus();
            }
        },
        focus() {
            this.focusedElement = document.activeElement;
        },
        keyboard(c) {
            if (this.focusedElement === null) {
                return;
            }

            this.focusedElement.focus();
            if (c === ',' && this.focusedElement.type === 'number') {
                return;
            }

            if (c === '<') {
                this.focusedElement.value = this.focusedElement.value.slice(0, -1);
            } else {
                this.focusedElement.value += c;
            }
            this.focusedElement.dispatchEvent(new Event('input'));
        },
        addCake(value, description) {
            const item = {
                seller: 998,
                value: value,
                description: description
            };

            item.price = formatMoney(item.value, {symbol: '€', precision: 2, thousand: '.', decimal: ','});
            this.sum += item.value;
            this.displaySum = formatMoney(this.sum, {symbol: '€', precision: 2, thousand: '.', decimal: ','});
            this.items.unshift(item);
        },
        filterKey(event) {
            switch (event.key) {
                case 'n':
                case 'b':
                case 'k':
                case 'l':
                    event.preventDefault();
                    break;
            }
        },

        hotkey(k) {
            window.dispatchEvent(new KeyboardEvent('keyup', {'key': k}));
        },

        async scanCamera() {
            const el = document.createElement('div');
            el.style.background = 'rgba(4, 4, 4, 0.6)';
            el.style.position = 'fixed';
            el.style.zIndex = 9999;
            el.style.left = 0;
            el.style.right = 0;
            el.style.bottom = 0;
            el.style.top = 0;
            el.innerHTML = '<div id="reader" style="background: white; width: min(600px, 95vw); height: 95vh; margin-inline: auto; margin-block: 2.5vh;"></div>';

            document.body.insertAdjacentElement('beforeend', el);

            const scanner = new Html5QrcodeScanner('reader', {
                fps: 60,
                qrbox: {
                    width: 250,
                    height: 150,
                },
                formatsToSupport: [
                    Html5QrcodeSupportedFormats.QR_CODE,
                    Html5QrcodeSupportedFormats.CODE_39,
                    Html5QrcodeSupportedFormats.EAN_13
                ]
            });

            el.addEventListener('click', (e) => {
                if (e.target !== el) {
                    return;
                }

                try {
                    scanner.clear();
                } catch (e) {
                    // ignore
                }
                el.remove();
            });

            let res = null;
            try {
                res = await new Promise((resolve) => scanner.render(resolve));
            } catch (e) {
                // ignore
            }

            try {
                await scanner.clear();
            } catch (e) {
                // ignore
            }

            el.remove();
            if (res) {
                this.form.scan = res;
                this.onSubmit();
            }
        }
    }
}
</script>

<style>
@media only screen and (max-width: 768px) {
    .cake-buttons > button {
        width: 100%;
        display: block;
    }
    .cake-buttons {
        margin: 0;
        padding: 0;
    }
    .mobile-thingy.row {
        padding: 0 !important;
        margin: 0 !important;
    }
    .jumbotron:has(.mobile-thingy), .jumbotron:has(.mobile-thingy) > div {
        margin-left: 0 !important;
        margin-right: 0 !important;
        padding-left: 0 !important;
        padding-right: 5px !important;
    }
}
</style>
