<template>
    <div>
        <b-modal id="modal-center" centered title="Produkt Einstellen" @shown="onShown" @hidden="onHidden" v-model="show" hide-footer no-close-on-backdrop no-close-on-esc>
            <b-form inline @submit="onSubmit">
                <b-form-input id="mail" type="email" ref="mail" v-model="form.mail" required placeholder="E-Mail Adresse" class="col-9" />
                    <b-button type="submit" variant="primary" class="col-2 offset-1">Login</b-button>
                </b-form>
        </b-modal>
    </div>
</template>

<script>
export default {
    mounted() {
        delete localStorage.token;
        this.$router.push('/');
    }
}
</script>
