<template>
    <div id="app" class="container">
        <div class="row justify-content-center align-self-center col-12 mt-md-5">
            <div class="col-12 text-left mb-4 mt-4" v-show="false">
                <img src="/logo.png" />
            </div>

            <b-navbar type="light" variant="danger" toggleable="md" class="col-12">
                <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>
                <b-navbar-brand to="/" class="d-none d-md-block"><font-awesome-icon :icon="shoppingCart" /> Basar</b-navbar-brand>

                <b-collapse is-nav id="nav_collapse">
                    <b-navbar-nav>
                        <b-nav-item-dropdown right v-if="hasCapability('cashier') || hasCapability('admin')">
                            <template slot="button-content">
                                <font-awesome-icon :icon="calculator" /> Kasse auswählen
                            </template>

                            <b-dropdown-item :to="`/register/${register.id}`" v-for="register in registers" :key="register.id">Kasse {{ register.name }}</b-dropdown-item>
                        </b-nav-item-dropdown>
                    </b-navbar-nav>

                    <b-navbar-nav class="ml-auto">
                        <b-nav-item v-if="hasCapability('admin')" to="/inventory"><font-awesome-icon :icon="chartLine" /> Kassensturz</b-nav-item>
                        <b-nav-item v-if="hasCapability('admin')" to="/registers"><font-awesome-icon :icon="euroSign" /> Kassen</b-nav-item>
                        <b-nav-item v-if="hasCapability('admin')" to="/users"><font-awesome-icon :icon="usersCog" /> Verkäufer*innen</b-nav-item>
                        <b-nav-item v-if="hasCapability('admin')" to="/config"><font-awesome-icon :icon="cogs" /> Konfiguration</b-nav-item>
                        <b-nav-item v-if="isAuthenticated" to="/logout"><font-awesome-icon :icon="signOut" /> Abmelden <small>({{ mail }})</small></b-nav-item>
                        <b-nav-item v-if="!isAuthenticated" to="/info" v-show="true"><font-awesome-icon :icon="barcode" /> Etiketten Info</b-nav-item>
                        <b-nav-item v-if="!isAuthenticated" to="/login" v-show="true"><font-awesome-icon :icon="signIn" /> Anmelden</b-nav-item>
                    </b-navbar-nav>
                </b-collapse>
            </b-navbar>

            <b-jumbotron class="row col-12 py-1">
                <div class="row col-12" style="padding-top: 20px; padding-bottom: 20px; overflow-x: auto;">
                    <router-view />
                </div>
            </b-jumbotron>

            <div class="col-12 text-right text-muted mb-5">
                <router-link to="/privacy">Datenschutz</router-link>
                &nbsp;|&nbsp;
                <a href="http://www.brk-neu-ulm.de/footer-menue-deutsch/service/impressum.html" target="_blank">Impressum</a>
            </div>
        </div>
    </div>
</template>

<style>
    html, body {
        height: 100%;
        max-height: 100%;
    }

    @media only screen and (max-width: 768px) {
        #app {
            padding-left: 0;
            padding-right: 0;
        }
        #app > .row {
            padding: 0;
            margin: 0;
        }
    }
</style>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { faUsersCog, faBarcode, faEuroSign, faBug, faCogs, faCalculator, faSignInAlt, faSignOutAlt, faShoppingCart, faChartLine } from '@fortawesome/free-solid-svg-icons';

export default {
    async mounted() {
        await this.refresh();

        if (this.$store.getters.isAuthenticated) {
            // this.$router.push('/logout');
        }
    },

    async beforeUpdate() {
        if (this.user !== this.$store.getters.mail) {
            await this.refresh();
        }
    },

    data: () => ({
        user: '',

        bug: faBug,
        cogs: faCogs,
        usersCog: faUsersCog,
        euroSign: faEuroSign,
        signIn: faSignInAlt,
        signOut: faSignOutAlt,
        shoppingCart: faShoppingCart,
        calculator: faCalculator,
        chartLine: faChartLine,
        barcode: faBarcode,

        registers: []
    }),

    methods: {
        async refresh() {
            this.user = this.$store.getters.mail;

            const user = `user/${this.$store.getters.mail}`;
            const isAdmin = this.$store.getters.hasCapability('admin');
            const isCashier = this.$store.getters.hasCapability('cashier');

            if (isAdmin || isCashier) {
                const mapping = [];

                if (isCashier) {
                    const config = (await axios.get('/config')).data;
                    mapping.push(...Object.keys(config.mapping).filter(id => config.mapping[id].includes(user)));
                }

                this.registers = (await axios.get('/registers')).data
                    .map(register => register.doc)
                    .filter(register => isAdmin || mapping.includes(register._id))
                    .sort((a, b) => a.name.localeCompare(b.name));
            }
        }
    },

    computed: mapGetters([ 'isAuthenticated', 'hasCapability', 'mail' ])
}
</script>
