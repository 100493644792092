import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const state = {
    count: 0,
    authenticated: false,
    token: null,
    session: null
};

const mutations = {
    authenticate(state, { token, session }) {
        state.authenticated = true;
        state.token = token;
        state.session = session;
    },

    logout(state) {
        state.authenticated = false;
        state.token = null;
        state.session = null;
    }
};

const actions = {
    authenticate({ commit }, { token, session }) {
        commit('authenticate', { token, session });
    },

    logout({ commit }) {
        commit('logout');
    }
};

const getters = {
    session: state => state.session,
    mail: state => state.session != null ? state.session.mail : '',
    isAuthenticated: state => state.authenticated,
    hasCapability: state => capability => state.authenticated && state.session.capabilities.includes(capability)
};

export default new Vuex.Store({
    state,
    getters,
    actions,
    mutations
});