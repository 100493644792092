<template>
    <div v-if="!editing">
        <div class="card">
            <b-table striped hover :fields="columns" :items="rows" select-mode="multi" selectable @row-selected="onRowSelected" ref="selectableTable">
                <template #cell(selected)="{ rowSelected }">
                    <template v-if="rowSelected">
                        <span aria-hidden="true">&check;</span>
                        <span class="sr-only">Selected</span>
                    </template>
                    <template v-else>
                        <span aria-hidden="true">&nbsp;</span>
                        <span class="sr-only">Not selected</span>
                    </template>
                </template>
                <template #cell(actions)="data">
                    <b-button @click="editing = data.item" v-if="data.item._id">
                        <font-awesome-icon :icon="faPenToSquare" />
                    </b-button>
                </template>
            </b-table>

            <div>
                <b-button @click="selectAllRows">Alle auswählen</b-button>
            </div>

            <b-card  bg-variant="light" text-variant="black" title="E-Mail verteilen" class="mt-5" v-show="selection.length > 0">
                <p class="text-muted"><small><b>Auswahl:</b> {{ selection.join(', ') }}</small></p>
                <p class="card-text">
                    Geben Sie einen Text an, der an alle ausgewählten Benutzer per E-Mail verschickt wird.
                </p>

                <div v-show="mail.sent">
                    <b-alert variant="danger" :show="!mail.success">Beim versenden ist ein Fehler aufgetreten!</b-alert>
                    <b-alert variant="success" :show="mail.success">E-Mail wurde erfolgreich versendet!</b-alert>
                </div>

                <b-form-input v-model="mail.subject" type="text" placeholder="E-Mail Betreff" class="mb-3"/>
                <b-form-textarea v-model="mail.text" placeholder="E-Mail Text eingeben" rows="3" max-rows="6" />
                <b-button v-on:click="sendMail" class="mt-3" variant="primary"><font-awesome-icon :icon="faEnvelope" /> E-Mail versenden</b-button>
                <b-button v-on:click="sendCredentials" class="mt-3 ml-3" variant="info"><font-awesome-icon :icon="faKey" /> Zugangsdaten versenden</b-button>
                <b-dropdown class="mt-3 ml-3">
                    <template slot="button-content"><font-awesome-icon :icon="faFilePdf" /> PDF erzeugen</template>
                    <b-dropdown-item v-on:click="getPdf('anmeldung')">Anmeldung &amp; Abholschein</b-dropdown-item>
                    <b-dropdown-item v-on:click="getPdf('abholung')">Abholung</b-dropdown-item>
                    <b-dropdown-item v-on:click="getPdf('auflistung')">Auflistung</b-dropdown-item>
                </b-dropdown>
                <b-button v-on:click="promote" class="mt-3 float-right" variant="warning"><font-awesome-icon :icon="faAngleDoubleUp" /> In Kassier-Account umwandeln</b-button>
            </b-card>
        </div>
    </div>
    <div v-else>
        <b>User {{editing.mail}} bearbeiten</b>

        <b-alert variant="danger" :show="!!error">
            <p>
                <b>Es ist ein Fehler aufgetreten!</b>
            </p>

            {{ error }}
        </b-alert>

        <b-form @submit="onSubmit">
            <b-form-group label="Vorname:" label-for="firstname">
                <b-form-input id="firstname" type="text" v-model="editing.firstname"  placeholder="Vorname" readonly />
            </b-form-group>

            <b-form-group label="Nachname:" label-for="lastname">
                <b-form-input id="lastname" type="text" v-model="editing.firstname"  placeholder="Nachname" readonly />
            </b-form-group>

            <b-form-group label="Verkaufsnummer:" label-for="number">
                <b-form-input id="number" type="number" v-model="editing.number"  placeholder="Verkaufsnummer" />
            </b-form-group>

            <b-form-group label="Wunschnummer:" label-for="desiredNumber">
                <b-form-input id="desiredNumber" type="text" v-model="editing.desiredNumber" placeholder="Wunschnummer" readonly />
            </b-form-group>

            <b-form-group label="Verkaufsnummer (letzter Basar):" label-for="previousNumber">
                <b-form-input id="perviousNumber" type="text" v-model="editing.previousNumber"  placeholder="Verkaufsnummer (letzter Basar)" readonly />
            </b-form-group>

            <b-form-group label="E-Mail Adresse:" label-for="mail">
                <b-form-input id="mail" type="text" v-model="editing.mail"  placeholder="E-Mail Adresse" readonly />
            </b-form-group>

            <b-form-group label="Telefon:" label-for="phone">
                <b-form-input id="phone" type="text" v-model="editing.phone"  placeholder="Telefon" readonly />
            </b-form-group>

            <b-form-group label="Telefon:" label-for="phone">
                <b-form-input id="phone" type="text" v-model="editing.phone"  placeholder="Telefon" readonly />
            </b-form-group>

            <b-form-group label="E-Mail Einwilligung:" label-for="mailConsent">
                <b-form-input id="mailConsent" type="text" v-model="editing.mailConsent" readonly  />
            </b-form-group>

            <b-form-group label="E-Mail Einwilligung (nächster Basar):" label-for="nextConsent">
                <b-form-input id="nextConsent" type="text" v-model="editing.nextConsent" readonly  />
            </b-form-group>

            <b-form-group label="Mithilfe:" label-for="help">
                <b-form-input id="help" type="text" v-model="editing.help" readonly  />
            </b-form-group>

            <b-button type="submit" variant="primary">
                <font-awesome-icon :icon="faFloppyDisk" />
                Speichern
            </b-button>
        </b-form>
    </div>
</template>

<script>
import axios from 'axios';
import { formatMoney } from 'accounting-js';
import { faEnvelope, faAngleDoubleUp, faKey, faFilePdf, faPlus, faFloppyDisk, faPenToSquare } from '@fortawesome/free-solid-svg-icons';

export default {
    async mounted() {
        this.config = (await axios.get('/config')).data;
        await this.reload();
    },
    data() {
        return {
            editing: false,
            error: null,

            config: {
                next: ''
            },

            columns: [
                { key: 'selected', label: ' ' },
                { key: 'active', label: ' ' },
                { key: 'number', label: '#' },
                { key: 'desiredNumber', label: 'W#' },
                { key: 'previousNumber', label: 'L#' },
                { key: 'mail', label: 'E-Mail Adresse' },
                { key: 'phone', label: 'Telefon' },
                { key: 'firstname', label: 'Vorname' },
                { key: 'lastname', label: 'Nachname' },
                { key: 'mailConsent', label: 'Einwilligung' },
                { key: 'nextConsent', label: 'Einwilligung Nächster' },
                { key: 'help', label: 'Mithilfe' },
                { key: 'actions', label: 'Aktionen' }
            ],

            rows: [],
            selection: [],

            mail: {
                subject: '',
                text: '',
                sent: false,
                success: false
            },

            faKey,
            faPlus,
            faFilePdf,
            faEnvelope,
            faFloppyDisk,
            faPenToSquare,
            faAngleDoubleUp
        };
    },

    methods: {
        getRowId(row) {
            return row.mail;
        },

        onRowSelected(items) {
            this.selection = items.map(e => e.mail);
        },

        selectAllRows() {
            if (this.selection.length === this.rows.length) {
                this.$refs.selectableTable.clearSelected();
            } else {
                this.$refs.selectableTable.selectAllRows();
            }
        },

        async reload() {
            const res = await axios.get('/users');
            this.rows = res.data.map(row => {
                row.doc.active = row.doc.previousNumber === undefined || (row.doc.previousNumber === -1 && (!row.doc.number || row.doc.number === '')) || row.doc.previousNumber === '' || row.doc.number === '' ? 'Inaktiv' : 'Aktiv';
                row.doc.total = formatMoney(row.doc.total * (100 - parseInt(this.config.commission, 10)) / 100, { symbol: '€', precision: 2, thousand: '.', decimal: ',' });
                row.doc.mailConsent = row.doc.mailConsent ? 'Ja' : '';
                row.doc.nextConsent = row.doc.nextConsent ? 'Ja' : '';
                row.doc.help = row.doc.help.join(', ');
                return row.doc;
            })
                .filter(user => !user.capabilities.includes('admin') && !user.capabilities.includes('cashier'))
                .sort((a, b) => {
                    if (a.number && b.number) {
                        return parseInt(a.number, 10) - parseInt(b.number, 10)
                    } else if (a.number) {
                        return 1;
                    } else if (b.number) {
                        return -1;
                    } else {
                        return a.mail.localeCompare(b.mail);
                    }
                });
        },

        async onSubmit() {
            try {
                this.error = null;
                const update = { number: this.editing.number };
                await axios.put(`/users/${this.editing.mail}`, update);
                this.editing = false;
                await this.reload();
            } catch (e) {
                this.error = e.response?.data?.error || e.message;
            }
        },

        async sendMail() {
            try {
                await axios.post('/mail', {
                    recipients: this.selection,
                    mail: this.mail
                });
                this.mail.sent = true;
                this.mail.success = true;
                this.mail.subject = '';
                this.mail.text = '';
            } catch (error) {
                this.mail.sent = true;
                this.mail.success = false;
            }
        },

        async sendCredentials() {
            try {
                await axios.post('/credentials', {
                    recipients: this.selection,
                    mail: this.mail
                });
                this.mail.sent = true;
                this.mail.success = true;
                this.mail.subject = '';
                this.mail.text = '';
            } catch (error) {
                this.mail.sent = true;
                this.mail.success = false;
            }
        },

        async promote() {
            if (confirm('Achtung dieser Vorgang ist irreversibel! Möchten Sie fortfahren?')) {
                try {
                    await axios.post('/promote', this.selection);
                    this.rows = this.rows.filter(row => !this.selection.includes(row.mail));
                    this.selection = [];
                } catch (error) {
                    alert('Es ist ein Fehler aufgetreten!');
                }
            }
        },

        async getPdf(type) {
            const form = document.createElement('form');
            const element = document.createElement('input');
            element.type = 'hidden';
            element.name = 'token';
            element.value = localStorage.getItem('auth');
            form.appendChild(element);

            this.selection.forEach(mail => {
                const element = document.createElement('input');
                element.type = 'hidden';
                element.name = 'mail[]';
                element.value = mail;
                form.appendChild(element);
            });

            form.method = 'POST';
            form.action = '/pdf/' + type;

            document.body.appendChild(form);
            form.submit();
        }
    }
}
</script>
