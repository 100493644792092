<template>
    <div class="col-12">
        <b-tabs>
            <b-tab v-for="tab in tabs" :key="tab" :title="tab" active>
                <b-card v-for="row in data[tab]" :key="row.id" :title="row.id" class="col-12">
                    <p class="card-text">
                        <pre>{{ row.doc }}</pre>
                    </p>
                    <b-button v-if="tab != 'config' && tab != 'inventory'" v-on:click="deleteRow(row.id)" variant="danger">Delete</b-button>
                </b-card>
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    async mounted() {
        await this.reload();
    },

    data() {
        return {
            data: {},
            tabs: []
        };
    },

    methods: {
        async reload() {
            this.tabs = [];
            this.data = {};

            (await axios.get('/database')).data.forEach(row => {
                const tab = row.id.split('/', 1)[0];
                if (!this.tabs.includes(tab)) {
                    this.data[tab] = [];
                    this.tabs.push(tab);
                }

                this.data[tab].push(row);
            });
        },

        async deleteRow(id) {
            if (confirm('Wirklich löschen?')) {
                await axios.delete(`/database/${id}`);
                await this.reload();
            }
        }
    }
}
</script>
