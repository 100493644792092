<template>
    <div class="col-12">
        <h6>Basar Konfiguration</h6>

        <b-form-group label="Veranstaltungsname:" label-for="nameInput">
            <b-form-input id="nameInput" type="text" v-model="form.name" required placeholder="Veranstaltungsname" />
        </b-form-group>

        <b-form-group label="Beschreibung:" label-for="descriptionInput">
            <b-form-textarea id="descriptionInput" v-model="form.description" placeholder="Beschreibung" :rows="3" :max-rows="6" />
        </b-form-group>

        <b-form-group label="% Provision für Veranstalter:" label-for="commissionInput">
            <b-form-input id="commissionInput" type="number" v-model="form.commission" required placeholder="Veranstalter Provision" />
        </b-form-group>

        <b-form-group label="Abholzeit zwischen:" label-for="timeInput">
            <b-form-input id="timeInput" type="text" v-model="form.time" required placeholder="Abholzeit zwischen" />
        </b-form-group>

        <b-form-group label="Nächster Bazar voraussichtlich:" label-for="nextInput">
            <b-form-input id="nextInput" type="text" v-model="form.next" required placeholder="Vorraussichtlicher Termin des Nächsten Bazars" />
        </b-form-group>

        <h6>Kassen Zuordnung</h6>
        <b-form-group v-for="register in registers" :label="`Kasse ${register.name}`" :key="register.id">
            <b-form-checkbox-group v-model="form.mapping[register.id]" :options="cashiers" />
        </b-form-group>

        <b-button v-on:click="save" class="mt-3 float-right" variant="success"><font-awesome-icon :icon="faSave" /> Änderungen Speichern</b-button>

        <h6>Berechtigungen</h6>

        <b-btn v-b-toggle.collapse class="m-1">Berechtigungen anzeigen/verstecken</b-btn>
        <b-collapse id="collapse">
            <div v-for="user in this.users" :key="user.mail">
                <b-button v-on:click="login(user.mail)" variant="info" size="sm" class="mb-2 mr-2"><font-awesome-icon :icon="faSignInAlt" /></b-button>
                <b>{{user.mail}}:</b> {{user.capabilities}}
            </div>
        </b-collapse>
    </div>
</template>

<script>
import axios from 'axios';
import { faSave, faSignInAlt } from '@fortawesome/free-solid-svg-icons';

export default {
    async mounted() {
        this.form = (await axios.get('/config')).data;
        if (!this.form.mapping) {
            this.form.mapping = {};
        }

        this.users = (await axios.get('/users')).data
            .map(row => row.doc);

        this.cashiers = this.users
            .filter(user => user.capabilities.includes('cashier'))
            .map(user => ({ text: `${user.firstname} ${user.lastname}`, value: user._id }));

        this.registers = (await axios.get('/registers')).data.map(row => {
            row.doc.id = row.id;
            if (!Object.prototype.hasOwnProperty.call(this.form.mapping, row.id)) {
                this.form.mapping[row.id] = [];
            }

            return row.doc;
        });
    },

    data() {
        return {
            users: [],
            cashiers: [],
            registers: [],

            form: {
                name: '',
                next: '',
                time: '',
                description: '',
                commission: '',
                mapping: {}
            },

            faSave,
            faSignInAlt
        };
    },

    methods: {
        async save() {
            try {
                await axios.post('/config', this.form);
            } catch(error) {
                alert('Es ist ein Fehler aufgetreten!');
            }
        },
        async login(user) {
            const res = await axios.get(`/auth/${user}`);
            this.$router.push(`/auth/${res.data}`);
        }
    },
}
</script>
