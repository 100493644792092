import Vue from 'vue'
import Router from 'vue-router'

import axios from 'axios';
import jwtDecode from 'jwt-decode';

import store from './store';

import Home from './views/Home.vue'
import ResetPassword from './views/ResetPassword.vue'
import Login from './views/Login.vue'
import Auth from './views/Auth.vue'
import Logout from './views/Logout.vue'
import Product from './views/Product.vue'
import RegisterList from './views/RegisterList.vue'
import Configuration from './views/Configuration.vue'
import UserList from './views/UserList.vue'
import Inventory from './views/Inventory.vue'
import Register from './views/Register.vue'
import Privacy from './views/Privacy.vue'
import Database from './views/Database.vue'
import Info from './views/Info.vue'

Vue.use(Router);

const router = new Router({
    routes: [{
        path: '/',
        component: Home,
    }, {
        path: '/Privacy',
        component: Privacy,
    }, {
        path: '/info',
        component: Info,
        meta: { onlyGuest: true }
    }, {
        path: '/login',
        component: Login,
        meta: { onlyGuest: true }
    }, {
        path: '/auth/:token',
        component: Auth,
    }, {
        path: '/reset/:token',
        component: ResetPassword,
    }, {
        path: '/product/:id',
        component: Product,
        meta: { onlyAuthenticated: true }
    }, {
        path: '/logout',
        component: Logout,
        meta: { onlyAuthenticated: true }
    }, {
        path: '/users',
        component: UserList,
        meta: { onlyAdmin: true }
    }, {
        path: '/config',
        component: Configuration,
        meta: { onlyAdmin: true }
    }, {
        path: '/inventory',
        component: Inventory,
        meta: { onlyAdmin: true }
    }, {
        path: '/registers',
        component: RegisterList,
        meta: { onlyAdmin: true }
    }, {
        path: '/db',
        component: Database,
        meta: { onlyAdmin: true }
    }, {
        path: '/register/:id',
        component: Register
    }]
});

router.beforeEach((to, from, next) => {
    if (localStorage.getItem('auth') !== null && !store.getters.isAuthenticated) {
        const token = localStorage.getItem('auth');
        store.dispatch('authenticate', {
            token: token,
            session: jwtDecode(token)
        });
        axios.defaults.headers.common = { 'Authorization': `bearer ${token}` };
    }

    if (to.matched.some(record => record.meta.onlyGuest)) {
        if (store.getters.isAuthenticated) {
            return next({ path: '/' });
        }
    } else if (to.matched.some(record => record.meta.onlyAuthenticated)) {
        if (!store.getters.isAuthenticated) {
            return next({ path: '/login' });
        }
    } else if (to.matched.some(record => record.meta.onlyAdmin)) {
        if (!store.getters.isAuthenticated) {
            return next({ path: '/login' });
        } else if (!store.getters.hasCapability('admin')) {
            return next({ path: '/' });
        }
    }
    return next();
});

export default router;
