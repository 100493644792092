<template>
    <div class="col-12">
        <b-card no-body>
            <b-tabs card>
                <b-tab v-for="register in registers" :title="`Kasse ${register.name}`" :key="register.id">
                    <b-form @submit="onSubmit" v-if="show">
                        <b-form-group :label="`Anzahl: ${denomination.text}`" v-for="denomination in denominations" :key="`${register.id}/${denomination.value}`">
                            <b-form-input type="number" v-model="inventory[register.id][denomination.value]" />
                        </b-form-group>

                        <b-button type="submit" variant="primary" class="float-right mb-3">
                            <font-awesome-icon :icon="faSave" /> Speichern
                        </b-button>
                    </b-form>
                </b-tab>
            </b-tabs>
        </b-card>
    </div>
</template>

<script>
import axios from 'axios';
import { formatMoney } from 'accounting-js';
import { faSave } from '@fortawesome/free-solid-svg-icons';

export default {
    async mounted() {
        const res = await axios.get('/registers');
        this.inventory = (await axios.get('/inventory')).data;

        this.registers = res.data.map(row => {
            if (!Object.prototype.hasOwnProperty.call(this.inventory, row.doc.id)) {
                this.inventory[row.doc.id] = {};
                this.denominations.forEach(d => this.inventory[row.doc.id][d.value] = '');
            }

            return row.doc;
        });
    },
    data() {
        return {
            denominations: [100, 50, 20, 10, 5, 2, 1, .5, .2, .1, .05, .02, .01].map(d => ({
                text: formatMoney(d, { symbol: '€', thousand: '.', decimal: ',' }),
                value: d
            })),

            registers: [],
            inventory: {},
            show: true,

            faSave
        };
    },

    methods: {
        async onSubmit(evt) {
            evt.preventDefault();

            try {
                await axios.put('/inventory', this.inventory);
            } catch (error) {
                alert('Es ist ein Fehler aufgetreten!');
            }
        }
    }
}
</script>
